<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-spin :spinning="this.deliver1.loading">
        <a-form-model ref='form' :model='form' layout='inline'>
          <a-form-model-item prop='brandCode'>
            <a-select v-model='form.brandCode' style="width: 200px" @change="changeBrand">
              <a-select-option value="">
                全部品牌公司
              </a-select-option>
              <a-select-option :value="item.brandCode" v-for="(item, index) in brandList" :key="index">
                {{item.brandName}}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item prop='start'>
            <a-range-picker
                v-model="dateArr"
                :format="[formatType, formatType]"
                :show-time="{
                hideDisabledOptions: true
              }"
                :disabled-date="disabledDate"
                @change="dateChange"
            />
          </a-form-model-item>

          <a-form-model-item>
            <a-button type='primary' @click='handleSubmit'>
              查询
            </a-button>
          </a-form-model-item>
          <a-form-model-item>
            <a-button @click='handleReset'>
              重置
            </a-button>
          </a-form-model-item>
        </a-form-model>
        <div>
          <div class='table-operator'>
            <a-list style="margin-top:15px;">
              <a-button type='primary' icon='reload' @click='$refs.table.refresh(true);$refs.table1.refresh(true);'>刷新</a-button>
              <span style="margin-left: 50px;margin-right: 20px;" >当前推广业绩：{{this.topResult.currentSpreaderAmount}}</span>
<!--              <span style="margin-right: 20px;" >当前未推广业绩：{{this.topResult.currentNotSpreaderAmount}}</span>-->
              <span style="margin-right: 20px;" >奖励推广业绩：{{this.topResult.orderAmount}}</span>
<!--              <span style="margin-right: 20px;" >奖励未推广业绩：{{this.topResult.fastOrderAmount}}</span>-->
              <span style="margin-right: 20px;" >奖励积分：{{this.topResult.totalIntegral}}</span>
            </a-list>

          </div>

          <s-table ref='table1' rowKey='createTime' size='default' :columns='columns' :scroll='{ x: "100%"}' :data='loadData' :showPagination="false">
            <span slot='action' slot-scope='text, record'>
                  <template>
                    <span>
                          <a-divider type='vertical' />
                          <a @click='handleInitial1(record)'>奖励</a>
                    </span>
                  </template>
            </span>
            <span slot='brandName' slot-scope='text, record'>
                  <template>
                    <span>
                          <a @click='handleOpenDetail(record)'>{{ text }}</a>
                    </span>
                  </template>
            </span>
          </s-table>

          <div style="height: 50px;width: 100%"></div>

          <s-table ref='table' rowKey='createTime' size='default' :columns='columns1' :scroll='{ x: "100%"}' :data='loadData1' :showPagination="false">
            <span slot='brandName' slot-scope='text, record'>
              <template>
                <a @click='openDetail(record)'>{{ text?text:'未设置' }}</a>
              </template>
            </span>
          </s-table>
        </div>

    </a-spin>

    <a-modal :title="'奖励积分审核-('+this.clickBrandName+')'" :destroyOnClose='true' :maskClosable='true' width="80%"
             @cancel='()=>this.deliver1.visible=false' @ok='()=>this.deliver1.visible=false' :footer="null"
             :visible='deliver1.visible' :confirmLoading='deliver1.loading'>
      <template >
        <a-form-model ref='form2' :model='deliver2.form' :rules='deliver2.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>

          <a-descriptions :column="4" style="width:90%;margin-left: auto;margin-right: auto;" >
            <a-descriptions-item label='份额总数'>{{dashboard.finishedShareCount}}</a-descriptions-item>
            <!--                  <a-descriptions-item label='商户份额数'>{{dashboard.merchantShareCount}}</a-descriptions-item>-->
            <a-descriptions-item label='推广人份额数'>{{dashboard.spreaderShareCount}}</a-descriptions-item>
            <!--                  <a-descriptions-item label='用户份额数'>{{dashboard.memberShareCount}}</a-descriptions-item>-->
            <a-descriptions-item label='未推广人份额数'>{{dashboard.notSpreaderShareCount}}</a-descriptions-item>
            <a-descriptions-item label='总奖励金额'>{{dashboard.totalAmount}}</a-descriptions-item>


            <a-descriptions-item label='奖励推广业绩'>
              <a-input v-model='dashboard.performance' style="width: 100px;" @change="countShareCount"/>
            </a-descriptions-item>
            <!--                  <a-descriptions-item label='用户总数'>{{dashboard.memberCount}}</a-descriptions-item>-->
            <a-descriptions-item label='奖励用户金额' :span="3">{{dashboard.memberAmount}}</a-descriptions-item>
<!--            <a-descriptions-item label='奖励未推广业绩'>-->
<!--              <a-input v-model='dashboard.notFast' style="width: 100px;" @change="countShareCount"/>-->
<!--            </a-descriptions-item>-->
<!--            <a-descriptions-item label='奖励商户金额'>{{dashboard.merchantAmount}}</a-descriptions-item>-->
            <!--                  <a-descriptions-item label='份值'>{{  dashboard.integralRewardValue}}</a-descriptions-item>-->
            <!--                  <a-descriptions-item label='未奖励不加速业绩'>-->
            <!--                    <a-input v-model='dashboard.notFast' style="width: 100px;" @change="countShareCount"/>-->
            <!--                  </a-descriptions-item>-->

            <a-descriptions-item label='超出份额数' :span="4">{{dashboard.overShare}}</a-descriptions-item>
            <!--                  <a-descriptions-item label='满足份额数'>{{dashboard.fillShareCount}}</a-descriptions-item>-->
            <!--                  <a-descriptions-item label='不满足份值'>{{dashboard.notFillValue}}</a-descriptions-item>-->
            <!--                  <a-descriptions-item label='不满足份额数'>{{dashboard.notFillShareCount}}</a-descriptions-item>-->

            <!--                  <a-descriptions-item label='正常份额数' :span="4">{{dashboard.normalFillValue}}</a-descriptions-item>-->
            <a-descriptions-item label='推广份值'>{{dashboard.spreaderShare}}</a-descriptions-item>
            <a-descriptions-item label='未推广份值' :span="3">{{dashboard.notSpreaderShare}}</a-descriptions-item>
            <a-descriptions-item :span="4">
              <!--                    <a-popconfirm title='确定发放吗？' ok-text='确认' cancel-text='取消' placement="bottom"-->
              <!--                                  @confirm='sendReward'>-->
              <!--                      <a-button type='primary'  style="float: right;" :loading="deliver1.loading">  发放奖励  </a-button>-->
              <!--                    </a-popconfirm>-->
              <a-button type='primary'  style="float: right;" @click="openReviewWin">  发放奖励(预览)  </a-button>
            </a-descriptions-item>

          </a-descriptions>
        </a-form-model>
      </template>
    </a-modal>

    <a-modal title='奖励预览' :destroyOnClose='true' :maskClosable='true'  width="70%" :scroll="{ y: 300 }"
             @cancel='()=>this.deliver3.visible=false'
             ok-text='确认发放' cancel-text='取消' @ok='sendReward'
             :visible='deliver3.visible' :confirmLoading='deliver3.loading'>

      <a-form-model ref='form3' :model='deliver3.form' layout='inline'>
        <a-form-model-item prop='memberName'>
          <a-input v-model='deliver3.form.masterName' style="width: 200px" placeholder="姓名" />
        </a-form-model-item>

        <a-form-model-item prop='memberMobile'>
          <a-input v-model='deliver3.form.masterMobile' style="width: 200px" placeholder="电话" />
        </a-form-model-item>

        <a-form-model-item prop='valueName'>
          <a-select v-model="deliver3.form.valueName" placeholder="份值" style="width: 200px">
            <a-select-option :value="undefined">
              全部
            </a-select-option>
            <a-select-option value="未推广">
              未推广
            </a-select-option>
            <a-select-option value="推广0.5">
              推广0.5
            </a-select-option>
            <a-select-option value="推广1">
              推广1
            </a-select-option>
            <a-select-option value="推广1.5">
              推广1.5
            </a-select-option>
            <a-select-option value="推广2">
              推广2
            </a-select-option>
            <a-select-option value="推广2.5">
              推广2.5
            </a-select-option>
            <a-select-option value="推广3">
              推广3
            </a-select-option>
            <a-select-option value="推广3.5">
              推广3.5
            </a-select-option>
            <a-select-option value="推广4">
              推广4
            </a-select-option>
            <a-select-option value="推广4.5">
              推广4.5
            </a-select-option>
            <a-select-option value="推广5">
              推广5
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-button type='primary' @click='handleSubmit1'>
            查询
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click='handleReset1'>
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>

      <a-descriptions>
        <a-descriptions-item label="奖励积分合计">{{ this.deliver3.totalCount}}</a-descriptions-item>
      </a-descriptions>
      <s-table id="table3" ref='table3' rowKey='id' size='default' :columns='columns3' :scroll="{ y: 500 }" :data='loadData3' :showPagination="false">

      </s-table>
    </a-modal>

    <a-modal title='当前推广业绩明细' :destroyOnClose='true' :maskClosable='true'  width="70%" :scroll="{ y: 300 }"
             @cancel='()=>this.deliver4.visible=false'
             :footer="null" :visible='deliver4.visible' :confirmLoading='deliver4.loading'>
      <a-descriptions>
        <a-descriptions-item label="品牌公司">{{ this.deliver4.form.brandName}}</a-descriptions-item>
        <a-descriptions-item label="当前推广业绩">{{ this.deliver4.form.currentSpreaderAmount}}</a-descriptions-item>
      </a-descriptions>
      <s-table id="table4" ref='table4' rowKey='id' size='default' :columns='columns4' :scroll="{ y: 500 }" :data='loadData4' :showPagination="false">
          <span slot='memberBianMa' slot-scope='text, record'>
                    <template>
                      <span>
                            {{getUserCode(record.memberType,record.memberLevel)}}
                      </span>
                    </template>
              </span>
          <span slot='spType' slot-scope='text, record'>
                    <template>
                      <span>
                            {{getUserCode(record.spType,record.spLevel)}}
                      </span>
                    </template>
          </span>
          <span slot='action' slot-scope='text, record'>
                    <template>
                      <span>
                            <a @click='handleDeleteRecord(record)'>清除</a>
                      </span>
                    </template>
          </span>
      </s-table>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import {Ellipsis, STable} from '@/components'
import moment from "moment";
import {
  queryBrand,
  queryJiangLiAreaList,
  queryJiangLiCashHeard,
  queryJiangLiCashList, queryRewardDetailList,
  reViewReward,
  sendRewardBody,
  deleteRewardMark
} from "@/api/cash";
import TagSelectOption from "@/components/TagSelect/TagSelectOption";



const columns = [
  {
    width: 100,
    title: '品牌公司',
    dataIndex: 'brandName',
    scopedSlots: { customRender: 'brandName' }
  },
  // {
  //   width: 80,
  //   title: '未奖励业绩',
  //   dataIndex: 'notReward',
  //   scopedSlots: { customRender: 'notReward' }
  // },
  // {
  //   width: 80,
  //   title: '未奖励加速',
  //   dataIndex: 'notFast',
  //   scopedSlots: { customRender: 'notFast' }
  // },
  {
    width: 80,
    title: '当前推广业绩',
    dataIndex: 'currentSpreaderAmount',
    scopedSlots: { customRender: 'currentSpreaderAmount' }
  },
  // {
  //   width: 80,
  //   title: '当前未推广业绩',
  //   dataIndex: 'currentNotSpreaderAmount',
  //   scopedSlots: { customRender: 'currentNotSpreaderAmount' }
  // },
  {
    width: 80,
    title: '剩余份额数',
    dataIndex: 'addShareCount',
    scopedSlots: { customRender: 'addShareCount' }
  },// 已完成目标的总份额数 新增份额数->原始份额数(已完成目标的总份额数：addShareCount)20240421版本
  {
    width: 80,
    title: '扣除份额数',
    dataIndex: 'substractShareCount',
    scopedSlots: { customRender: 'substractShareCount' }
  },
  {
    width: 80,
    title: '实时累计值',
    dataIndex: 'cumulativeValueBalance',
    scopedSlots: { customRender: 'cumulativeValueBalance' }
  },
  // {
  //   width: 80,
  //   title: '奖励业绩',
  //   dataIndex: 'orderAmount',
  //   scopedSlots: { customRender: 'orderAmount' }
  // },
  // {
  //   width: 80,
  //   title: '奖励加速',
  //   dataIndex: 'fastOrderAmount',
  //   scopedSlots: { customRender: 'fastOrderAmount' }
  // },
  {
    width: 80,
    title: '奖励推广业绩',
    dataIndex: 'orderAmount',
    scopedSlots: { customRender: 'orderAmount' }
  },
  // {
  //   width: 80,
  //   title: '奖励未推广业绩',
  //   dataIndex: 'fastOrderAmount',
  //   scopedSlots: { customRender: 'fastOrderAmount' }
  // },
  {
    width: 80,
    title: '奖励积分',
    dataIndex: 'totalIntegral',
    scopedSlots: { customRender: 'totalIntegral' }
  },
  {
    width: 80,
    title: '可用积分85%',
    dataIndex: 'cash',
    scopedSlots: { customRender: 'cash' }
  },
  {
    width: 80,
    title: '可用积分15%',
    dataIndex: 'exchangeIntegral',
    scopedSlots: { customRender: 'exchangeIntegral' }
  },
  {
    width: 80,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  },
  //
  // {
  //   width: 100,
  //   title: '进行中份额数',
  //   dataIndex: 'totalShareCount',
  //   scopedSlots: { customRender: 'totalShareCount' }
  // },进行中份额数20240611 删除
  // 已完成目标的总份额数 总份额数->进行中额数(已完成目标的总份额数：totalShareCount)20240421版本
  // {
  //   width: 100,
  //   title: '总累计值',
  //   dataIndex: 'cumulativeValue',
  //   scopedSlots: { customRender: 'cumulativeValue' }
  // },20240427 删除
]

const columns1 = [
  {
    width: 100,
    title: '品牌公司',
    dataIndex: 'brandName',
    scopedSlots: { customRender: 'brandName' }
  },
  {
    width: 100,
    title: '奖励日期',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  // {
  //   width: 80,
  //   title: '奖励业绩',
  //   dataIndex: 'orderAmount',
  //   scopedSlots: { customRender: 'orderAmount' }
  // },
  // {
  //   width: 80,
  //   title: '奖励加速',
  //   dataIndex: 'fastOrderAmount',
  //   scopedSlots: { customRender: 'fastOrderAmount' }
  // },
  {
    width: 80,
    title: '奖励推广业绩',
    dataIndex: 'orderAmount',
    scopedSlots: { customRender: 'orderAmount' }
  },
  // {
  //   width: 80,
  //   title: '奖励未推广业绩',
  //   dataIndex: 'fastOrderAmount',
  //   scopedSlots: { customRender: 'fastOrderAmount' }
  // },
  {
    width: 100,
    title: '剩余份额数',
    dataIndex: 'shareValue',
    scopedSlots: { customRender: 'shareValue' }
  },// 已完成目标的总份额数 新增份额数->原始份额数(发奖励时已完成目标的总份额数：addShareCount)20240421版本
  // {
  //   width: 100,
  //   title: '待有效份额数',
  //   dataIndex: 'thisShareCount',
  //   scopedSlots: { customRender: 'thisShareCount' }
  // },// 已完成目标的总份额数 总份额数->进行中额数(发奖励时的当轮份额数：totalShareCount)20240421版本
  {
    width: 80,
    title: '总累计值',
    dataIndex: 'cumulativeValue',
    scopedSlots: { customRender: 'cumulativeValue' }
  },//20240427 删除
  {
    width: 80,
    title: '实时累计值',
    dataIndex: 'cumulativeValueBalance',
    scopedSlots: { customRender: 'cumulativeValueBalance' }
  },
  {
    width: 80,
    title: '奖励积分',
    dataIndex: 'totalIntegral',
    scopedSlots: { customRender: 'totalIntegral' }
  },
  {
    width: 80,
    title: '分享积分85%',
    dataIndex: 'cash',
    scopedSlots: { customRender: 'cash' }
  },

  {
    width: 80,
    title: '兑换积分15%',
    dataIndex: 'exchangeIntegral',
    scopedSlots: { customRender: 'exchangeIntegral' }
  },

]

const columns3 = [
  {
    width: 100,
    title: '姓名',
    dataIndex: 'memberName',
    scopedSlots: { customRender: 'memberName' }
  },
  {
    width: 100,
    title: '电话',
    dataIndex: 'memberMobile',
    scopedSlots: { customRender: 'memberMobile' }
  },
  {
    width: 100,
    title: '份额数',
    dataIndex: 'memberShareCount',
    scopedSlots: { customRender: 'memberShareCount' }
  },
  {
    width: 100,
    title: '分值',
    dataIndex: 'valueName',
    scopedSlots: { customRender: 'valueName' }
  },
  {
    width: 100,
    title: '奖励积分',
    dataIndex: 'rewardIntegral',
    scopedSlots: { customRender: 'rewardIntegral' }
  },
]

const columns4 = [
  {
    width: 80,
    title: '订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 80,
    title: '下单时间',
    dataIndex: 'orderCreateTime',
    scopedSlots: { customRender: 'orderCreateTime' }
  },
  {
    width: 70,
    title: '订单金额',
    dataIndex: 'orderAmount',
    scopedSlots: { customRender: 'orderAmount' }
  },
  {
    width: 70,
    title: '购买人',
    dataIndex: 'memberName',
    scopedSlots: { customRender: 'memberName' }
  },
  {
    width: 50,
    title: '购买编码',
    dataIndex: 'memberType',
    scopedSlots: { customRender: 'memberBianMa' }
  },
  {
    width: 80,
    title: '购买人手机号',
    dataIndex: 'memberMobile',
    scopedSlots: { customRender: 'memberMobile' }
  },
  {
    width: 80,
    title: '业绩归属人',
    dataIndex: 'spName',
    scopedSlots: { customRender: 'spName' }
  },
  {
    width: 60,
    title: '归属人编码',
    dataIndex: 'spType',
    scopedSlots: { customRender: 'spType' }
  },
  {
    width: 70,
    title: '上级运营',
    dataIndex: 'presidentName',
    scopedSlots: { customRender: 'presidentName' }
  },
  {
    width: 80,
    title: '分公司简称',
    dataIndex: 'areaName',
    scopedSlots: { customRender: 'areaName' }
  },
  {
    width: 80,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  },
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'OperatorList',
  components: {
    TagSelectOption,
    STable, Ellipsis
  },
  data() {
    return {
      topResult:{
        notReward:0,//未奖励业绩
        notFast:0,//未奖励加速
        orderAmount:0,//奖励业绩
        fastOrderAmount:0,//奖励加速
        totalIntegral:0,//奖励积分
      },
      brandName:'',
      loading: true,
      dashboard: {}, //表头用户数统计
      clickBrandName:"",//点击打开窗口时候的品牌名称
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        start: moment(baseStart).startOf('day').format(formatType ),
        end: moment(baseEnd).endOf('day').format(formatType),
        brandCode:"",
      },
      form1: {
        masterName: "",
        masterMobile:"",
      },
      brandList:[],//品牌公司列表
      deliver1: {
        visible: false,
        form: {
        },
        loading: false
      },
      deliver2: { visible: false,loading: false, form: {},
        rules: {
          spreaderAmount: [{ required: true, message: '请填写奖励推广业绩', trigger: 'change' }],
          notSpreaderAmount: [{ required: true, message: '请填写奖励未推广业绩', trigger: 'change' }],
        },
      },
      deliver3: {
        visible: false,loading: false, form: {},
        totalCount:0,
      },
      deliver4: {
        visible: false,loading: false, form: {},
        totalCount:0,
      },
      integralRewardValue:0,
      // 表头
      columns,
      columns1,
      columns3,
      columns4,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        // if(!brandCode || brandCode==""){
        //   return
        // }
        let params = Object.assign(parameter, this.queryParam,this.form);
        params.size = 999;

        return queryJiangLiCashList(params).then(datum => {
          let  notReward = 0;//未奖励业绩
          let   currentSpreaderAmount = 0;//当前推广业绩
          let   currentNotSpreaderAmount = 0//当前未推广业绩
          let  notFast=0;//未奖励加速
          let  orderAmount=0;//奖励业绩
          let  fastOrderAmount=0;//奖励未推广业绩
          let  totalIntegral=0;//奖励积分

          for(let item of datum){
            // notReward = notReward + item.notReward|0;
            currentSpreaderAmount = currentSpreaderAmount + item.currentSpreaderAmount|0;
            currentNotSpreaderAmount = currentNotSpreaderAmount + item.currentNotSpreaderAmount|0;
            notFast = notFast + item.notFast|0;
            orderAmount = orderAmount + item.orderAmount|0;
            fastOrderAmount = fastOrderAmount + item.fastOrderAmount|0;
            totalIntegral = totalIntegral + item.totalIntegral|0;
          }
          this.topResult = {
            currentSpreaderAmount:currentSpreaderAmount,
            currentNotSpreaderAmount:currentNotSpreaderAmount,
            // notReward:notReward,
            notFast:notFast,
            orderAmount:orderAmount,
            fastOrderAmount:fastOrderAmount,
            totalIntegral:totalIntegral,
          }
          // debugger
          this.$previewRefresh()
          return {
            records: datum
          }
        })
      },
      loadData1: parameter => {
        let params = Object.assign(parameter, this.queryParam,this.form,);
        params.size = 999;
        return queryJiangLiAreaList(params)
            .then(datum => {
              // debugger;
              this.$previewRefresh()
              return {
                records: datum
              }
            })
      },
      loadData3: parameter => {
        let tt = this.deliver2.form;
        let params = Object.assign(parameter,this.queryParam,this.deliver2.form,this.deliver3.form);
        params.performance = this.dashboard.performance;
        params.notFast = this.dashboard.notFast;
        params.size = 999;
        return reViewReward(params).then(datum => {
          // debugger;
          let tempCount = 0;
          let record = [];
          for(let item of datum){
            if(item.rewardIntegral>0&& item.memberShareCount>0){
              record.push(item)
              tempCount = tempCount+ item.rewardIntegral;
              console.log(tempCount);
            }
          }
          this.deliver3.totalCount = tempCount;
          return {
            records: record
          }
        })
      },
      loadData4: parameter => {
        let params = Object.assign(parameter, this.queryParam,this.deliver4.form,);
        params.size = 999;
        return queryRewardDetailList(params).then(datum => {
              this.$previewRefresh()
              return {
                records: datum.records
              }
            })
      },
    }
  },
  created() {
    this.queryBrand();//品牌加载

  },
  methods: {
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
      this.$refs.table1.refresh(true)
      this.loading=true;
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = moment(baseStart).startOf('day').format(this.formatType);
      this.form.end = moment(baseEnd).endOf('day').format(this.formatType);
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
      this.$refs.table1.refresh(true)
    },
    handleSubmit1() {
      this.$refs.table3.refresh(true)
    },
    handleReset1() {
      this.$refs.form3.resetFields()
      this.$refs.table3.refresh(true)
    },
    openDetail(record){
      let tempCreateDate = record.createTime;
      let tempDate = record.date;
      this.$router.push({ path: '/points/jiangli/edit', query: { id: record.areaId,createTime:tempCreateDate,date:tempDate } })
    },
    handleInitial1(record) {
      if(this.brandList.length<1){
        this.$message.error("没有品牌或等待品牌加载完成！")
        return;
      }
      this.clickBrandName = this.getBrandName(record.brandCode);
      console.log(this.brandList);

      this.deliver2.form = {
        // notFast : record.notFast,
        // notSpreaderAmount:record.notSpreaderAmount,
        brandCode :  record.brandCode,
      }

      this.deliver1.loading = true
      let params = Object.assign({brandCode:record.brandCode,start:this.form.start,end:this.form.end});
      // debugger
      queryJiangLiCashHeard(params).then(result =>{
        // debugger
        result.fillShareCount=result.fillShareCount||0;//满足份额数
        result.notFillShareCount=result.notFillShareCount||0;//不满足份额数
        result.memberShareCount=result.memberShareCount||0;//用户份额数
        result.merchantShareCount=result.merchantShareCount||0;// 商户份额数
        result.fillValue=result.fillValue||0;//满足份值
        result.notFillValue=result.notFillValue||0;//不满足份值
        result.merchantCount=result.merchantCount||0;//商户总数

        result.spreaderShareCount=result.spreaderShareCount||0;//推广人份额数
        result.notSpreaderShareCount=result.notSpreaderShareCount||0;//未推广人份额数
        result.totalAmount=result.totalAmount||0;//总奖励金额
        result.spreaderAmount=result.spreaderAmount||0;//奖励推广业绩
        result.memberAmount=result.memberAmount||0;//奖励用户金额
        result.notSpreaderAmount=result.notSpreaderAmount||0;//奖励未推广业绩
        result.merchantAmount=result.merchantAmount||0;//奖励商户金额
        result.spreaderShare=result.spreaderShare||0;//推广份值
        result.notSpreaderShare=result.notSpreaderShare||0;//未推广份值
        result.overShare=result.overShare||0;//超出份额数
        result.multiplySum = result.multiplySum||0;//推广人份额数,乘以倍数的


        // this.deliver2.form.performance = result.performance||0;
        this.deliver2.form.performance = result.performance||0;
        this.deliver2.form.notFast = result.notFast||0;
        this.deliver2.form.queryTime = result.queryTime;

        this.dashboard = result;
        this.deliver1.loading = false
        this.deliver1.visible = true
      });


    },
    openReviewWin(){
      if(this.dashboard.finishedShareCount<=0){
        this.$message.error("总份额数为0，不能预览")
        return;
      }

      if(this.dashboard.performance>0 || this.dashboard.notFast>0){
        this.deliver3.loading = false
        this.deliver3.visible = true
      }else{
        this.$message.info('暂无奖励可发放，预览失败！');
      }

    },
    //发送奖励
    sendReward(){
      if(this.dashboard.finishedShareCount<=0){
        this.$message.error("总份额数为0，不能发送奖励");
        return;
      }
      if(this.dashboard.performance>0 || this.dashboard.notFast>0){
        // let brandCode = this.form.brandCode;
        let params = Object.assign(this.deliver2.form);
        params.performance = this.dashboard.performance;
        params.notFast = this.dashboard.notFast;
        debugger
        this.deliver3.loading = true;
        sendRewardBody(params).then(result =>{
          this.deliver1.loading = false;
          this.deliver1.visible=false;

          this.deliver3.loading = false;
          this.deliver3.visible=false;
          // debugger;
          this.$message.success('操作成功');
          this.$refs.table.refresh(true)
        });
      }else{
        this.$message.info('暂无奖励可发放！');
      }
    },
    //动态计算分值
    countShareCount(){

      let performance = this.dashboard.performance;//奖励推广业绩
      // let notFast = this.dashboard.notFast;//奖励未推广业绩
      let notFast = 0;//默认为0
      let spreaderShareCount = this.dashboard.spreaderShareCount;//推广人份额数
      let multiplySum = this.dashboard.multiplySum;//推广人份额数,乘以倍数的
      let notSpreaderShareCount = this.dashboard.notSpreaderShareCount;//推广人份额数
      debugger

      // 奖励用户金额= 奖励推广业绩*17.5%
      let tempMemberAmount = performance * 0.175;//奖励用户金额
      // 奖励商户金额= 奖励未推广业绩*25%
      let tempMerchantAmount = notFast * 0.25;//奖励商户金额

      // 奖励用户金额-去除小数点
      let memberAmount = Math.round(tempMemberAmount * 100) / 100;
      // 奖励商户金额-去除小数点
      let merchantAmount = Math.round(tempMerchantAmount * 100) / 100;

      //总奖励金额= 奖励用户金额+奖励商户金额
      let totalAmount = memberAmount+merchantAmount;

      // 推广份值= 总奖励金额*80%÷推广人份额数（取乘以倍数的）
      let tempSpreaderShare = totalAmount * 0.8 / multiplySum;
      //未推广份值= 总奖励金额*20%÷未推广人份额数
      let tempNotSpreaderShare = totalAmount * 0.2 / notSpreaderShareCount;
      // 推广份值-去除小数点
      let spreaderShare = Math.round(tempSpreaderShare * 100) / 100;
      // 未推广份值-去除小数点
      let notSpreaderShare = Math.round(tempNotSpreaderShare * 100) / 100;


      // debugger
      this.dashboard.memberAmount = memberAmount;//奖励用户金额
      this.dashboard.merchantAmount = merchantAmount;//奖励商户金额
      this.dashboard.totalAmount = totalAmount;//总奖励金额
      this.dashboard.spreaderShare = spreaderShare;//推广份值
      this.dashboard.notSpreaderShare = notSpreaderShare;//未推广份值


    },
    // getFillStatusStr(text) {
    //   if(text =='1'){
    //     return '满足';
    //   } else if(text =='2'){
    //     return '不满足';
    //   } else if(text =='3'){
    //     return '正常';
    //   }
    // },
    //获取品牌公司列表
    queryBrand(){
      let month  = moment().startOf('date').format('YYYYMM');
      let params = Object.assign({pageNum:1,pageSize:100,month:month});
      queryBrand(params).then(result=>{
        // debugger;
        let list = result.records;
        //顺便设置选择到的值
        this.brandList = list;

        if(list && list.length>0){
          // this.form2 = {brandCode:list[0].brandCode};
          this.handleSubmit();
        }else{
          this.$message.error("缺少品牌公司，数据无法展示！");
        }

      })
    },
    changeBrand(){
      this.handleSubmit();
    },
    getBrandName(brandCode){
      for(let item of this.brandList){
        if(item.brandCode==brandCode){
          this.brandName =  item.brandName;
          break;
        }
      }
      return this.brandName;
    },
    //品牌公司打开推广业绩明细
    handleOpenDetail(record){
      this.deliver4.form = {
        brandName :record.brandName,
        currentSpreaderAmount:record.currentSpreaderAmount,
        brandCode :  record.brandCode,
      }

      this.deliver4.loading = true;

      this.deliver4.visible = true;
      let tabbb = this.$refs.table4;

      if(tabbb){
        tabbb.refresh(true);
      }

      console.log(record)
    },
    getUserCode(type,level){
      if(type=='store'){
        return '门店'
      }else if(type=='center') {
        // return '服务中心';
        return 'SF';
      }else if(type=='president') {
        // return '运营总裁';
        return 'SY'
      }else if(type=='normal'){
        if(level=='center'){
          // return '备选服务';
          return 'BF';
        } else if(level=='president'){
          // return '备选运营';
          return 'BY';
        }
        // return '用户';
        return 'Y';
      }else{
        return '';
      }
    },
    //标记删除记录
    handleDeleteRecord(record){

      let params = Object.assign({orderId:record.orderId});

      this.deliver4.loading = true;
      deleteRewardMark(params).then(result =>{
        this.$message.success('操作成功');
        this.$refs.table4.refresh(true)
      });
    }

  }
}
</script>
